import React from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { I18nextProvider } from 'react-i18next';
import { Router, BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'styled-components';
import { CookiesProvider } from 'react-cookie';
import Loadable from 'react-loadable';
import { store, persistor } from './store';
// import { App } from './app/views/index'
import i18n from './i18n/index';
import { history } from './store/history';
import * as APIString from './constants/apiString';
import * as Version from './constants/envConfig';
import InitApp from './App';
import GlobalStyles from './features/theme/globalStyles';
import { detectTheme } from './features/theme/useTheme';
import { getConfigByKey } from './features/tenantConfig/services';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';
import { insertScript } from '~root/app/functions/add-script';

import { isNode } from '~utils/ssr';

// const environment = APIString.REACT_APP_ENV;
// if (Version.VERSION && environment) {
//   Sentry.init({
//     dsn: 'https://4ca7335d46ed4935b7c20c152a10dff5@o166441.ingest.sentry.io/5870387',
//     environment,
//     release: Version.VERSION,
//   });
// }

export const provideComponent = function (Component, element) {
  const reactMountFn = element.childElementCount === 0 ? render : hydrate;

  reactMountFn(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Router history={history}>
            <CookiesProvider>
              <I18nextProvider i18n={i18n}>
                <ThemeProvider theme={detectTheme(tenantEnvConfig.ui_theme)}>
                  {React.cloneElement(Component)}
                </ThemeProvider>
              </I18nextProvider>
            </CookiesProvider>
          </Router>
        </BrowserRouter>
      </PersistGate>
    </Provider>,
    element,
  );
};

const runApp = () => {
  if (isNode()) {
    return;
  }
  const mountElement = document.getElementById('root');
  insertScript();
  window.onload = () => {
    Loadable.preloadReady().then(() => {
      provideComponent(
        <>
          <InitApp />
          <GlobalStyles />
        </>,
        mountElement,
      );
    });
  };
};

runApp();
