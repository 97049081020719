import React, { Component, Suspense } from 'react';
import { updateCurrentLive } from '~features/live-schedule/services';
import { getFavorite } from '~features/myList/services';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getConfigByKey } from '~features/tenantConfig/services';
import moment from 'moment';
import queryString from 'query-string';
import OverlayLoading from '~components/loading/overlayLoading';
import {
  getDetails,
  getRelate,
  postProgress,
  getMenu,
  getAdsVideo,
  updateDisclaimer,
  getEpglist,
  getReportTemplates,
  uploadImage,
  setVolume,
  setCurrentLink,
  postFirstFiveSecPlay,
  getMuxEnvKey,
  getLiveInfo,
  getRecommend,
  getInfoDetail,
  getScrubberThumbnail,
  getGapContents,
  getProgramShow,
  getConfigCalenderEpg,
  updateStatusGetGapContens,
} from '../services';

import 'videojs-contrib-ads/dist/videojs.ads.css';
import 'videojs-ima/dist/videojs.ima.css';
import 'videojs-ima/dist/videojs.ima.min';
import 'video.js/dist/video.min.js';
import 'video.js/dist/video-js.css';
import { getDetailView } from '../../landingpage/services';
import { setIsShowLogin } from '../../auth/services';
import * as landingPageService from '../../landingpage/services';
import { getLinkPlay } from '../../../utils/utils';
import { checkAutoPlay } from '../function/detectAutoplay';

import { EPISODE_TYPE, CHANNEL_TYPE, SHOW_TYPE, CURATED_LIVE } from '../constants';

import * as init from '../function';
import { handleCuratedLive, _handleRewatchCuratedLive } from '../function/initCuratedLive';
import { pushEventFirebasePlayer } from '../components/pushEventFirebase';
import { DivStyle } from './playerClipModeCss';
import jquery from 'jquery';
// import DetectAdBlock from './../components/detectAdBlock'
import LoginLivePlayer from '../components/loginLivePlayer';
import Upgrade from '~features/payperview/views';
import PopupShare from '~features/share/components/popup';
import TitleSeo from '../components/titleSeo';
import ClipMode from '../components/player/clipMode';
import FullMode from '../components/player/fullMode';
import { endDrmToday } from '../function/drm';
import './playerPage.scss';
import '../styles/escondido';
import { postFavorite } from '~features/landingpage/services';
import { setOpen } from '~components/svodApp/services';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';
import { TENANT_NAME_ENUM, CONTENT_TYPE } from '~core/constants';
import lodash from 'lodash';
import { isProjectW } from '~core/method/authMethod';

class PlayerPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.videoPlayer = null;
    this.initHideVolume = null;
    this.initHideControl = null;
    this.initHideDescription = null;
    this.initHideSubtitle = null;
    this.progress = null;
    this.duration = null;
    this.viewHour = 0;
    this.timePushEvent = null;
    this.isFirst = true;
    this.intervalUpdateProgress = null;
    this.isInitedEvents = false;
    this.subtitleRender = false;
    this.isFirstPlay = true;
    this.isReadyAds = false;
    this.isDisplayCooldown = null;
    this.isDisplayEPG = null;
    this.isWatch = null;
    this.listEpg = null;
    this.backUrl = null;
    this.watched = false;
    this.checkCurrentLive = null;
    this.daySelected = moment().format('YYYY-MM-DD');
    this.originEntityDetail = null;
    this.programSlug = null;
    this.playerRef = React.createRef();
    this.livePage = true;
    this.playerShaka = null;
    this.state = {
      loading: false,
      width: window.innerWidth,
      height: window.innerHeight,
      progressWidth: window.innerWidth - 40,
      volumeHeight: 100,
      entityDetail: null,
      title: '',
      description: '',
      image: '',
      seasons: [],
      releasted: [],
      isLoadRelated: true,
      epgs: [],
      isBlackOut: false,
      isCooldown: false,
      isRewatchCuratedLive: false,
      isLoadEPG: true,
      progress: 0,
      duration: 0,
      currentTimeString: '00:00',
      isMute: false,
      isEndVideo: false,
      lastVolume: 0.5,
      audioSelected: 1,
      // subtitleSelected: 0,
      isFullScreen: false,
      tracks: [],
      location: '',
      adTagUrl: null,
      popupUpgrade: false,
      isNotifyDisplay: false,
      isLoginDisplay: false,
      isHideErrorDefault: false,
      id: '',
      entity: null,
      parentEps: null,
      infoShow: null,
      isHasSubcription: false,
      totalEpsBySs: '',
      isLive: false,
      clipMode: true,
      isBlockAds: false,
      entityTrailer: null,
      renderYoutube: false,
      resetEpg: false,
      isOpenPopupShare: false,
      errMessage: '',
      payperview: false,
      isRestrictions: false,
      redirect: null,
      tokenDrm: null,
      tvod: false,
      paymentType: 'free',
    };
    this.translations = {
      episode: 'Tập',
      nextEpisode: 'Tập sau',
      prevEpisode: 'Tập trước',
    };
    this.configs = {};
  }

  componentWillMount() {
    const { auth } = this.props;
    if (this.videoPlayer) {
      this.videoPlayer.dispose();
    }
    if (auth && auth.account && auth.account.profile && auth.account.profile.has_subscription) {
      this.setState({
        isHasSubcription: true,
      });
    }
  }

  componentDidMount() {
    const { detailPage, location, match, language, dispatchSetCurrentLink } = this.props;
    const { state } = location;
    const newSlug = match.params.slug;
    const paramQuery = queryString.parse(location.search, {
      decode: false,
    });
    if (getConfigByKey('ui_theme') === TENANT_NAME_ENUM.ODV) {
      window.document.body.classList.add('vjs-player-page');
    }

    window.addEventListener('online', () => {
      this._reloadPlayer();
    });
    window.addEventListener('onorientationchange', function () {
      this._setPropertyControlBar();
    });
    const that = this;
    window.addEventListener('orientationchange', () => {
      window.addEventListener('resize', that.orientationChange);
    });
    window.addEventListener('resize', that.orientationChange, true);
    // this.props.dispatchGetMenu()
    window.scrollTo(0, 0);

    this._checkIsContentShow(state, newSlug);

    if (paramQuery && paramQuery.token && !this.state.popupUpgrade) {
      const page = this.checkPathName();
      const currentLink = detailPage.player.currentLink || `/${language}/${page}/${newSlug}`;
      dispatchSetCurrentLink(currentLink);
      this.setState({
        popupUpgrade: true,
        redirect: currentLink,
        isRestrictions: detailPage.player.isRestrictions,
      });
    }
  }

  componentWillUnmount() {
    const { entityDetail, isHasSubcription, tokenDrm } = this.state;
    const { account } = this.props;
    const that = this;
    if (this.link) {
      this.link.remove();
    }
    this.setLoading();
    if (this.videoPlayer && this.entityDetail.video_source !== 1) {
      const isPreview = isHasSubcription ? true : entityDetail.is_watchable;
      if (isPreview) {
        this._updateProgress(
          entityDetail.id,
          (this.videoPlayer && this.videoPlayer.currentTime()) || '1',
        );
      }
      if (this.videoPlayer && this.videoPlayer.ima && this.videoPlayer.ima.changeAdTag) {
        this.videoPlayer.ima.changeAdTag(null);
      }
      // TODO : Update - When Unmout Player - #Bug - 48710
      this._updateProgress(
        entityDetail.id,
        (this.videoPlayer && this.videoPlayer.currentTime()) || '1',
      );
      this._clearTimeInterval();
      // this.videoPlayer.dispose();
    }
    this.props.dispatchSetIsShowLogin(false);
    window.document.title = `Watch Movies and Shows | ${getConfigByKey('site_name')}`;
    if (this.playerShaka) {
      endDrmToday(tokenDrm);
      this.playerShaka.destroy();
    }
    window.document.body.classList.remove('vjs-player-page');
    window.document.body.classList.remove('vjs-player-fullmode');
    window.removeEventListener('orientationchange', () => {
      window.addEventListener('resize', that.orientationChange);
    });
    window.removeEventListener('resize', that.orientationChange);
    if (this.videoPlayer) {
      this.videoPlayer.dispose();
    }
  }

  componentWillReceiveProps(nextProps) {
    let newState = {};
    const callbackFuns = [];
    const { entityDetailFromParent, hasEntity } = nextProps;

    const { auth } = this.props;
    const { slug } = this.props.match.params;
    const newSlug = nextProps.match.params.slug;
    const originState = this.props.location.state;
    const { entityDetail, popupUpgrade } = this.state;
    const { state } = nextProps.location;
    const { payperview, isRestrictions } = this.state;
    const paramQuery = queryString.parse(this.props.location.search, {
      decode: false,
    });
    const newparamQuery = queryString.parse(nextProps.location.search, {
      decode: false,
    });
    if (state && state.payperview && !this.state.payperview && entityDetail) {
      newState = {
        ...newState,
        payperview: true,
        isHasSubcription: true,
        isRestrictions: state.isRestrictions,
        popupUpgrade: state.isPopup === false ? state.isPopup : popupUpgrade,
      };
      // this.setState({
      //   payperview: true,
      //   isHasSubcription: true,
      //   isRestrictions: state.isRestrictions,
      //   popupUpgrade: state.isPopup === false ? state.isPopup : popupUpgrade,
      // })
    }
    if (
      newSlug !== slug ||
      (paramQuery && newparamQuery && paramQuery.trailer !== newparamQuery.trailer)
    ) {
      const isPayperview =
        originState && originState.programSlug === this.programSlug ? payperview : false;
      newState = {
        ...newState,
        entityTrailer: null,
        renderYoutube: false,
        isHasSubcription:
          (auth.account && auth.account.profile && auth.account.profile.has_subscription) ||
          isPayperview,
        payperview: isPayperview || false,
        isRestrictions:
          (originState && originState.programSlug !== this.programSlug) || !originState
            ? false
            : isRestrictions,
      };

      // this.setState(
      //   {
      //     entityTrailer: null,
      //     renderYoutube: false,
      //     isHasSubcription:
      //       (auth.account &&
      //         auth.account.profile &&
      //         auth.account.profile.has_subscription) ||
      //       isPayperview,
      //     payperview: isPayperview || false,
      //     isRestrictions:
      //       (originState && originState.programSlug !== this.programSlug) ||
      //       !originState
      //         ? false
      //         : isRestrictions,
      //   },
      //   () => {
      //     this._checkIsContentShow(state, newSlug)
      //   },
      // )
    }
    if (!hasEntity || lodash.isEmpty(entityDetailFromParent)) {
      callbackFuns.push(() => {
        this._checkIsContentShow(state, newSlug);
      });
      return;
    }

    if (this.props.entityDetailFromParent.id !== entityDetailFromParent.id) {
      newState = {
        ...newState,
        entityDetail: entityDetailFromParent || {},
      };
      entityDetailFromParent.slug &&
        callbackFuns.push(() => {
          this._asyncGetVideo(entityDetailFromParent.slug);
        });
    }
    this.setState(
      {
        ...newState,
      },
      () => {
        // this._checkIsContentShow(state, newSlug)
        callbackFuns.map(fn => {
          if (typeof fn !== 'function') {
            return;
          }
          fn();
        });
      },
    );
    if (nextProps.volumeLive !== this.props.volumeLive) {
      this.handleMuted(nextProps.volumeLive);
    }
  }

  setLoading = (value = false) => {
    this.setState({ loading: value });
  };

  orientationChange = () => {
    const that = this;
    if (this.entityDetail && this.entityDetail.video_source) {
      this.setState({ width: window.innerWidth }, () => {
        window.removeEventListener('resize', that.orientationChange);
      });
      return;
    }
    if (!this.videoPlayer) {
      return;
    }
    const $player = jquery(this.videoPlayer.el_);
    if ($player.hasClass('vjs-curated-live')) {
      return;
    }
    this.setState({ width: window.innerWidth }, () => {
      window.removeEventListener('resize', that.orientationChange);
    });
  };

  checkPathName = () => {
    const { match } = this.props;
    const regex = new RegExp('/:lg/((?:landing)|(?:detail))/:slug', 'g');
    const matchUrl = regex.exec(match.path || '');
    const page = matchUrl.length === 2 ? matchUrl[1] : 'detail';
    return page;
  };

  _redirectToEpisodeOfShow = (content = null) => {
    if (!content) {
      return;
    }

    const { isHasSubcription } = this.state;
    const { language } = this.props;
    if (!content.current_season || !content.current_season.current_episode) {
      return this.props.history.replace(`/${language}/404`);
    }
    if (!content.current_season) {
      return this.props.history.replace(`/${language}/landing/${content.slug}`);
    }
    const { current_episode } = content.current_season;
    const isPreview = isHasSubcription
      ? true
      : current_episode.can_preview || content.payment_infors;
    this.programSlug = content.slug;
    if (isPreview) {
      let episodeIndex = current_episode.episode_index;
      if (episodeIndex < 10) {
        episodeIndex = `0${episodeIndex}`;
      }
      const page = this.checkPathName();
      return this.props.history.replace(
        `/${language}/${page}/${current_episode.slug}?eps=${episodeIndex}`,
      );
    }
    if (getConfigByKey('ui_theme') === TENANT_NAME_ENUM.PROJECTW) {
      return;
    }
    return this.props.history.replace(`/${language}/landing/${content.slug}`);
  };

  _checkIsContentShow = (state, newSlug) => {
    if (this.hasEntity) {
      return;
    }
    if (state && state.type === SHOW_TYPE) {
      this.setLoading(true);
      this.props
        .dpGetDetailView(newSlug)
        .then(res => this._redirectToEpisodeOfShow(res))
        .catch(err => {
          console.log('err');
        })
        .finally(() => {
          this.setLoading(false);
        });
    } else {
      this.programSlug = state && state.type !== EPISODE_TYPE && newSlug;
      this.originEntityDetail = this.entityDetail;
      this._asyncGetVideo(newSlug);
    }
  };

  _clearTimeInterval = (rewatchLive = false) => {
    const player = this.videoPlayer;
    const lessTime = this.viewHour % 300;
    const restMinuteTime = this.viewHour % 60;
    let idVideo = '';
    if (player && this.entityDetail.video_source !== 1) {
      player.dispose();
    } else {
      this.videoPlayer = null;
    }
    this.setState(
      {
        id: '',
        parentEps: null,
      },
      () => {
        if (this.state.entityTrailer) {
          idVideo = this.state.entityTrailer.id;
        }
        this.setState({
          id: this.entityDetail.rewatchId
            ? this.entityDetail.rewatchId
            : idVideo || this.entityDetail.id,
        });
      },
    );
    if (rewatchLive) {
      return;
    }

    if (restMinuteTime !== 0) {
      pushEventFirebasePlayer(
        this.originEntityDetail || this.entityDetail,
        'viewMinuteLog',
        restMinuteTime,
      );
    }
    if (lessTime) {
      pushEventFirebasePlayer(
        this.originEntityDetail || this.entityDetail,
        'viewhourLog',
        lessTime,
      );
      this.originEntityDetail = this.entityDetail;
    }
    this.viewHour = 0;
    this.watched = false;
    this.isFirstPlay = true;
    this.isReadyAds = false;
    clearInterval(this.checkCurrentLive);
    clearInterval(this.intervalPause);
    clearInterval(this.currentTimeInterval);
    clearInterval(this.timePushEvent);
    clearInterval(this.intervalUpdateProgress);
  };

  _reloadPlayer = () => {
    const player = this.videoPlayer;
    if (!player) {
      return;
    }
    if (!player.vhs || !player.vhs.playlists) {
      return;
    }

    player.vhs.playlists.load();
  };

  _resumeVideo = (currentTime = 0) => {
    let initdone = false;
    const { entityTrailer } = this.state;
    if (!currentTime || currentTime <= 0) {
      return;
    }
    const player = this.videoPlayer;
    const { entityDetail } = this.state;
    if (
      entityDetail.type === CHANNEL_TYPE ||
      entityDetail.type === CONTENT_TYPE.SIMULATED_LIVE_EVENT ||
      entityTrailer ||
      !player
    ) {
      return;
    }
    player.on('canplaythrough', () => {
      if (!initdone) {
        player.currentTime(currentTime);
        initdone = true;
      }
    });
    player.currentTime(currentTime);
    player.play();
  };

  _handleError = () => {
    const player = this.videoPlayer;
    const { entityDetail } = this.state;
    player.on('error', event => {
      player.pause();
      const error = player.error();

      if (error.code === 3) {
        player.src({ type: player.currentType(), src: player.currentSrc() });
        player.load();
        player.play();
      }
    });
    player.on('playing', () => {
      const error = player.error();
      if (error !== null) {
        player.pause();
      }
    });
  };

  _fitterSubtitle(tracks) {
    this.subtitleRender = true;
    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i];
      track.mode = 'hidden';
      if (this.videoPlayer.src() === this.entityDetail.link_play) {
        if (track.kind === 'subtitles' && track.language === tenantEnvConfig.default_locale) {
          track.mode = 'showing';
          track.subtitleRender = true;
        }
      }
    }
    this.subtitleRender = false;
    return tracks;
  }

  _setPropertyControlBar = () => {
    if (!this.videoPlayer) {
      return;
    }
    const controlBar = this.videoPlayer.getChild('controlBar');
    let height = jquery(controlBar.el()).outerHeight();
    if (height > 150) {
      height = 150;
    }
    window.document.documentElement.style.setProperty('--cb', `${height}px`);
  };

  _userActive = () => {
    this.videoPlayer.on('useractive', () => {
      this._setPropertyControlBar();
    });
  };

  _userInactive = () => {
    this.videoPlayer.on('userinactive', () => {
      this._setPropertyControlBar();
    });
  };

  _onSeeked = id => {};

  _updateProgress(id, curren_time) {
    const { entityTrailer } = this.state;
    if (entityTrailer || this.viewHour < 4 || curren_time < 4) {
      return;
    }
    this.props
      .dispatchPostProgress(id, curren_time)
      .then(() => {})
      .catch(() => {});
  }

  _checkAutoplay() {
    const player = this.videoPlayer;
    checkAutoPlay(player, this);
  }

  _renderNotify() {
    const { t } = this.props;
    const { isNotifyDisplay, isRewatchCuratedLive, entityDetail } = this.state;
    setTimeout(() => {
      this.subtitleRender = false;
    }, 500);
    const $player = jquery(this.videoPlayer.player_.el_);
    if (!isNotifyDisplay)
      this.setState(
        {
          isNotifyDisplay: true,
        },
        () => $player.find('.vjs-top-policy').addClass('vjs-ads'),
      );
    if (!isRewatchCuratedLive && entityDetail.type === CURATED_LIVE) {
      $player.removeClass('vjs-rewatching');
      $player.addClass('vjs-liveui');
      $player.addClass('vjs-live');
    }
  }

  _getLinkPlayTrailer = (paramQuery, entityDetail) => {
    const { dpGetDetailView } = this.props;
    let linkPlayTrailer = null;
    if (paramQuery && paramQuery.trailer) {
      linkPlayTrailer = entityDetail.trailers.map(trailer => {
        if (trailer.slug === paramQuery.trailer) {
          return trailer.title;
        }
      });
      return dpGetDetailView(paramQuery.trailer)
        .then(res => {
          entityDetail = {
            ...entityDetail,
            link_play: res.link_play,
            play_info: res.play_info,
            entityTrailer: {
              ...res,
              title: linkPlayTrailer ? linkPlayTrailer[0] : entityDetail.title,
            },
          };
          this.setState({
            entityTrailer: {
              ...res,
              title: linkPlayTrailer ? linkPlayTrailer[0] : entityDetail.title,
            },
          });
          return entityDetail;
        })
        .catch(() => {});
    }
    return entityDetail;
  };

  checkContentLive = entityDetail => {
    if (
      (entityDetail.type === CURATED_LIVE ||
        entityDetail.type === CHANNEL_TYPE ||
        tenantEnvConfig.ui_theme === 'escondido') &&
      !isProjectW()
    ) {
      window.document.body.classList.add('vjs-player-fullmode');
      this.setState({
        clipMode: false,
        isLive: true,
      });
    } else {
      window.document.body.classList.remove('vjs-player-fullmode');
      this.setState({
        clipMode: true,
        isLive: false,
      });
    }
    this.setLoading();
  };

  checkReWatchLive = currentLive => {
    const { state } = this.props.location;
    const { history } = this.props;
    const rewatch = (state && state.rewatchId) || this.rewatchId || this.props.rewatchId;
    if (rewatch && this.livePage) {
      const result = currentLive.items.filter(element => element.id === rewatch);
      if (result.length) {
        history.replace({
          ...history.location,
          state: { ...state, rewatchId: null },
        });
        this.livePage = false;
        this.isWatch = result[0];
        _handleRewatchCuratedLive(this, result[0], false, currentLive.items);
      }
      return true;
    }
    return false;
  };

  getLinkPlayContents = (response, currentLive, contentPayment) => {
    const { entityDetail, entityTrailer, isLive, tvod } = this.state;
    let linkPlay = getLinkPlay(entityDetail);
    if (entityDetail.type === CURATED_LIVE) {
      linkPlay = (response.current_live_cursor && response.current_live_cursor.link_play) || '';
      if (!response.current_live_cursor && !response.next_content && response.items.length > 0) {
        this.setState({
          isRewatchCuratedLive: true,
        });
      }
    }
    if (!entityTrailer && ((!entityDetail.is_watchable && !isLive) || (tvod && !contentPayment))) {
      linkPlay = '';
      this.setState({
        popupUpgrade: true,
        isHideErrorDefault: true,
        entity: entityDetail,
      });
    }
    if (entityDetail && !entityDetail.is_watchable && isLive) {
      linkPlay = '';
      this.setState({
        isLoginDisplay: true,
      });
    }
    if (currentLive) {
      linkPlay = entityDetail.link_play;
    }
    return linkPlay;
  };

  _asyncGetVideo = async (slug = '', currentLive = false) => {
    const {
      detailPage,
      location,
      t,
      match,
      paidContents,
      dpGetProgramShow,
      contentId,
      language,
      hasEntity,
    } = this.props;

    const { isHasSubcription, payperview, entityDetail: entityDetailLocal } = this.state;

    if (hasEntity && !entityDetailLocal) {
      return;
    }

    this.setLoading(true);
    const { params } = match;
    const that = this;
    let entityDetail = currentLive || entityDetailLocal;
    let programShow = null;
    let paramQuery = queryString.parse(location.search, { decode: false });
    let foundPaidContents = [];
    slug = contentId || slug;
    if (slug === '' && 'slug' in params) {
      slug = params.slug;
    }
    if (location.state && location.state.payperview) {
      const queryUrl = queryString.parseUrl(slug);
      slug = queryUrl.url;
      paramQuery = paramQuery.query;
    }
    try {
      if (!currentLive) {
        try {
          entityDetail = await this.props.dispatchGetDetail(slug, true, entityDetailLocal);

          if (entityDetail.type === EPISODE_TYPE) {
            const detailProgram = await dpGetProgramShow(entityDetail.id);
            programShow = detailProgram;
            this.programSlug = detailProgram.slug;
            entityDetail = {
              ...entityDetail,
              content_categories: detailProgram && detailProgram.content_categories,
              provider_slug: detailProgram && detailProgram.provider_slug,
              trailers: detailProgram && detailProgram.trailers,
            };
          }
          entityDetail = await this._getLinkPlayTrailer(paramQuery, entityDetail);
        } catch (err) {
          this.setState({ errMessage: err.message }, () => {
            pushEventFirebasePlayer(entityDetail, 'videoPlayStartFail');
          });
          this.setLoading();
        }
      }
      if (!entityDetail) {
        return;
      }

      if (entityDetail.type === SHOW_TYPE) {
        return this._redirectToEpisodeOfShow(entityDetail);
      }

      entityDetail = {
        ...entityDetail,
        infoShow: programShow,
      };

      this.entityDetail = entityDetail;
      this.checkContentLive(entityDetail);
      const replay = queryString.parse(this.props.location.search);

      init
        .initData(this, entityDetail, currentLive)
        .then(response => {
          this._clearTimeInterval(currentLive);
          const { entityTrailer } = this.state;
          const programDetail = programShow || entityDetail;

          this.setLoading();
          if (programShow && paidContents.length) {
            foundPaidContents =
              paidContents.filter(element => programShow.id === element.content_id) || [];
          }
          const contentPayment = foundPaidContents.length
            ? true
            : entityDetail?.show_info?.payment_infors ?? entityDetail.payment_infors;
          // return
          this.setState(
            {
              entityDetail: { ...entityDetail, ...response },
              title: entityTrailer ? entityTrailer.title : entityDetail.title,
              description: entityDetail.long_description,
              seasons: entityDetail.seasons,
              image: entityDetail.images.thumbnail,
              epgs: response.items || [],
              infoShow: programShow || null,
              renderYoutube: entityDetail.video_source,
              errMessage: '',
              isHasSubcription: payperview || contentPayment ? true : isHasSubcription,
              payperview:
                payperview || contentPayment
                  ? true
                  : (isHasSubcription &&
                      programDetail.payment_type !== 'tvod' &&
                      isHasSubcription) ||
                    false,
              tvod:
                (programDetail.can_preview === false || entityDetail.can_preview === false) &&
                programDetail.payment_type === 'tvod',
              paymentType: programDetail.payment_type,
            },
            () => {
              const { infoShow, isLive } = this.state;
              this.entityDetail = { ...entityDetail, ...response };
              this.checkReWatchLive(response);
              if (entityDetail.video_source !== 1) {
                this.handleBlackOut();
                const linkPlay = this.getLinkPlayContents(response, currentLive, contentPayment);
                init
                  .initPlayer(
                    this,
                    entityDetail,
                    replay,
                    linkPlay,
                    currentLive,
                    paramQuery,
                    this.props.isPending,
                    this.props.liveState,
                    t,
                  )
                  .then(() => {
                    const isPreview = isHasSubcription ? true : entityDetail.is_watchable;
                    if (!entityTrailer && !isLive && !isPreview) {
                      if (infoShow) {
                        return this.props.history.replace(`/${language}/landing/${infoShow.slug}`);
                      }
                      return this.props.history.replace(
                        `/${language}/landing/${entityDetail.slug}`,
                      );
                    }

                    init.initTextTrack(this, entityDetail, t);
                    this.videoPlayer.one('loadeddata', event => {
                      handleCuratedLive(that, that.state.entityDetail, response);
                    });
                    init.playerReady(this, detailPage, t, currentLive);
                  })
                  .catch();
              }
            },
          );
        })
        .catch(err => {
          this.setState({
            errMessage: err.message,
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

  _getSeason = () => {
    const { detailPage, dispatchGetSeason } = this.props;
    if (detailPage.group) {
      return new Promise((resolve, reject) => {
        dispatchGetSeason(detailPage.group)
          .then(response => {
            resolve(response);
            this.setState({
              totalEpsBySs: response,
            });
          })
          .catch(err => reject(err));
      });
    }
    return Promise.resolve(null);
  };

  _promiseGetEpgsList = (params, entityDetail) => {
    if (entityDetail.type === CHANNEL_TYPE || entityDetail.type === CURATED_LIVE) {
      return new Promise((resolve, reject) => {
        this.props
          .dispatchGetEpglist(params, entityDetail)
          .then(response => {
            resolve(response);
          })
          .catch(err => reject(err));
      });
    }
    return Promise.resolve({});
  };

  _promiseGetCuratedLive = (params, entityDetail) => {
    const rewatch = this.props.location.state && this.props.location.state.rewatchId;
    if (entityDetail.type === CURATED_LIVE && !rewatch) {
      return new Promise((resolve, reject) => {
        this.props
          .dpGetLiveInfo(entityDetail.id)
          .then(response => {
            resolve(response);
            this.handelCooldown(resolve);
          })
          .catch(err => reject(err));
      });
    }
    return Promise.resolve({});
  };

  _getRecommend = (idContent = null) => {
    const { entityDetail, parentEps, infoShow } = this.state;
    let idRecoment = parentEps || entityDetail.id;

    if (infoShow) {
      idRecoment = infoShow.id;
    }

    if (idContent) {
      idRecoment = idContent;
    }

    return new Promise((resolve, reject) => {
      this.props
        .dpGetRecommend(idRecoment)
        .then(response => {
          resolve(response);
        })
        .catch(errors => {
          reject(errors);
        });
    });
  };

  _getAds = entityDetail => {
    const { isHasSubcription, entityTrailer } = this.state;
    const params = {
      content_id: entityDetail.rewatchId ? entityDetail.rewatchId : entityDetail.id,
    };
    return new Promise((resolve, reject) => {
      if (isHasSubcription || entityTrailer) return resolve(null);
      if (entityDetail && entityDetail.tagAds) return resolve(entityDetail.tagAds);
      this.props
        .dispatchGetAdsVideo(params)
        .then(response => {
          const ads = response.ad_tag && response.ad_tag.ad_tag_url;
          resolve(ads);
        })
        .catch(errr => {
          resolve(null);
        });
    });
  };

  _getScrubberThumbnail = entityDetail =>
    new Promise((resolve, reject) => {
      this.props
        .dpGetScrubberThumbnail(entityDetail.id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          console.log('err', error);
        });
    });

  handleMuted = volume => {
    if (!this.videoPlayer) return;
    const player = this.videoPlayer;

    if (volume === 1) {
      this.videoPlayer.muted(false);
      player.volume(1);
    }
    if (volume === 0) this.videoPlayer.muted(true);
  };

  handleImaLive = player => {
    const { isLoginDisplay, isNotifyDisplay, entityDetail } = this.state;
    const $player = jquery(this.videoPlayer.player_.el_);
    if (!entityDetail || isLoginDisplay) {
      return;
    }
    if (!isNotifyDisplay)
      this.setState(
        {
          isNotifyDisplay: true,
        },
        () => $player.find('.vjs-top-policy').addClass('vjs-ads'),
      );
  };

  handleBlackOut = () => {
    const { epgs } = this.state;
    const nowTime = moment();
    const epgItem = null;
    if (!epgs.length) {
      return;
    }

    let isBlackOut = false;
    epgs.forEach(epg => {
      if (!(parseInt(epg.start_at) <= nowTime.unix() && parseInt(epg.end_at) > nowTime.unix())) {
        return;
      }
      if (epg && epg.is_blackout) {
        isBlackOut = true;
      }
    });

    this.setState({ isBlackOut });
  };

  handelCooldown = (response = null) => {
    const { current_live_cursor } = response;
    const isCooldown = true;
    if (response && current_live_cursor && current_live_cursor.isBlackOut) {
      this.setState({ isCooldown });
    }
  };

  _setDuration = value => {
    this.videoPlayer.duration = function () {
      return value;
    };
  };

  render() {
    const { showLoginAction } = this.props;
    const {
      popupUpgrade,
      entityDetail,
      isLoginDisplay,
      isBlockAds,
      isOpenPopupShare,
      isLive,
      clipMode,
      errMessage,
      loading,
      id,
      paymentType,
    } = this.state;

    const setOpenSharePopup = value => {
      this.setState({
        isOpenPopupShare: value,
      });
    };
    if (!entityDetail || loading) return <OverlayLoading customeClass="v-detailPage--loading" />;

    if (errMessage) {
      return (
        <DivStyle className="v-detailPage">
          <p className="v-detailPage__err">{errMessage}</p>
        </DivStyle>
      );
    }
    if (!id) return null;
    return (
      <Suspense fallback={<OverlayLoading />}>
        {clipMode ? (
          <ClipMode state={this.state} component={this} />
        ) : (
          <FullMode component={this} state={this.props.state} isBlockAds={isBlockAds} />
        )}
        <TitleSeo {...this.state} />
        {/* <DetectAdBlock component={this} /> */}
        {isLoginDisplay ? (
          <LoginLivePlayer entityDetail={{ entityDetail, showLoginAction }} />
        ) : null}
        <Upgrade
          {...this}
          modalShow={popupUpgrade}
          open={popupUpgrade}
          target="player"
          paymentType={paymentType}
          hideModal={() => this.setState({ popupUpgrade: false })}
        />
        {entityDetail && isLive && (
          <PopupShare
            entityDetail={entityDetail}
            open={isOpenPopupShare}
            setOpen={value => setOpenSharePopup(value)}
            target="player"
          />
        )}
      </Suspense>
    );
  }
}

const mapStateToProps = state => ({
  menu: state.home.menu,
  account: state.auth.account,
  detailPage: state.detailPage,
  landingPage: state.landingPage,
  locale: state.auth.locale,
  auth: state.auth,
  tenantConfigs: state.tenantConfigs,
  root: state.root,
  tenantConfig: state.tenantConfig,
  paidContents: state.auth.paidContents,
  volumeLive: state.liveSchedule.volume,
  myList: state.myList.favoriteList,
  language: state.root.language,
  isPending: state.global.statusRefund.isPending,
  liveState: state.global.liveEventStatus,
});

const mapDispatchToProps = {
  dispatchGetMenu: () => getMenu(),
  dispatchGetDetail: (slug, isDispatch = true, contentData = null) =>
    getDetails(slug, isDispatch, contentData),
  dispatchGetRelate: slug => getRelate(slug),
  dispatchPostProgress: (content_id, progress) => postProgress(content_id, progress),
  dispatchGetAdsVideo: params => getAdsVideo(params),
  dispatchGetSeason: seasonId => landingPageService.getSeasonNew(seasonId),
  dispatchUpdateDisclaimer: value => updateDisclaimer(value),
  dispatchGetEpglist: payload => getEpglist(payload),
  dispatchGetReportTemplates: () => getReportTemplates(),
  dispatchUploadImage: data => uploadImage(data),
  dispatchSetVolume: value => setVolume(value),
  dispatchSetCurrentLink: value => setCurrentLink(value),
  dispatchPostFirstFiveSecPlay: contentId => postFirstFiveSecPlay(contentId),
  dpGetMuxEnvKey: () => getMuxEnvKey(),
  dpGetLiveInfo: idChannel => getLiveInfo(idChannel),
  dispatchSetIsShowLogin: value => setIsShowLogin(value),
  dpGetRecommend: contentId => getRecommend(contentId),
  dpGetInfoDetail: slug => getInfoDetail(slug),
  dpGetScrubberThumbnail: id => getScrubberThumbnail(id),
  dpGetDetailView: id => getDetailView(id),
  dpGetGapContents: (idChannel, gapDuration) => getGapContents(idChannel, gapDuration),
  dpGetProgramShow: idContent => getProgramShow(idContent),
  dpGetConfigCalenderEpg: () => getConfigCalenderEpg(),
  dpSetOpenSvodApp: payload => setOpen(payload),
  dpPostFavorite: (id, type) => postFavorite(id, type),
  dispatchGetFavorite: () => getFavorite(),
  dispatchUpdateStatusGetGapContens: value => updateStatusGetGapContens(value),
  dpUpdateCurrentLive: params => updateCurrentLive(params),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PlayerPage)),
);
