import moment from 'moment';
import { isMobile } from 'react-device-detect';
import videojs from 'video.js';
import * as contribAds from 'videojs-contrib-ads';
import * as videojsIma from 'videojs-ima';
import * as shaka from 'videojs-shaka';
import jquery from 'jquery';
import queryString from 'query-string';
import { getConfigByKey } from '~features/tenantConfig/services';
import addChilren from './initControlBar';
import { muxConfig } from '../plugins/mux';
import { hotkeysConfig } from '../plugins/hotkey';
import { CURATED_LIVE, CHANNEL_TYPE, EPISODE_TYPE } from '../constants';
import * as init from './index';
import '../components/Warning_AgeRestrictions/style/style.scss';

import { initScubberThumbnail } from './initScubberThumbnail';
import { checkAutoPlay } from './detectAutoplay';
import { getGapContent } from './initCuratedLive';

import 'videojs-hotkeys';
import 'videojs-mux';
import initDrm from './drm';
import { drmEme } from './drmEme';
// import 'videojs-contrib-hls'
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';
import { LIVE_EVENT, SIMULATED_LIVE_EVENT, TENANT_NAME_ENUM } from '~core/constants';
import '~components/player/plugins/simulatedLiveEvent';
import 'videojs-contrib-quality-levels';
import '~core/videojs/plugins/quality-selector';
import '~core/videojs/plugins/source-selector';

window.videojs = videojs;
const mime = require('mime-types');

const initIma = function init(options) {
  this.ima = new videojsIma.default(this, options);
};

const initShaka = function initShakas(options) {
  this.shaka = new shaka(this, options);
};

const initData = (component, entityDetail, renderLive) => {
  let epgListtemp = {};
  const { tenantConfig } = component.props;

  const params = {
    channel_id: entityDetail.id,
    schedule_date: moment().format('YYYY-MM-DD'),
  };
  component.configs.mux = muxConfig({
    envKey: tenantConfig.integration_config && tenantConfig.integration_config.mux_key,
    component,
  });

  const getEpg = () => {
    if (renderLive) {
      return new Promise((resolve, reject) => {
        const data = { items: renderLive.items };
        resolve(data);
      });
    }
    return component._promiseGetEpgsList(params, entityDetail);
  };

  const getSeason = () => {
    if (renderLive) {
      return new Promise((resolve, reject) => {
        resolve(null);
      });
    }
    return component._getSeason(entityDetail);
  };

  const result = new Promise((resolve, reject) => {
    let responseEpgsList = {};
    const promises = [
      getSeason(),
      getEpg(),
      component._promiseGetCuratedLive(params, entityDetail),
      component._getAds(entityDetail),
      // component._getScrubberThumbnail(entityDetail),
    ];
    Promise.all(promises)
      .then(data => {
        let tagAds = null;
        let scrubberThumbnail = null;
        data.map((value, index) => {
          if (index === 0) {
            entityDetail.current_season = value;
          }
          if (index === 1) {
            responseEpgsList = value;
          }
          if (index === 2) {
            epgListtemp = value;
          }
          if (index === 3 && value) {
            tagAds = value || null;
          }
          if (index === 4 && value) {
            scrubberThumbnail = value;
          }
        });
        resolve({
          ...entityDetail,
          ...responseEpgsList,
          ...epgListtemp,
          tagAds,
          scrubberThumbnail,
        });
      })
      .catch(error => {
        reject(error);
      });
  });
  return result;
};

const initPlayer = async (component, entityDetail, replay, linkPlay, renderLive = false) => {
  const paramQuery = queryString.parse(component.props.location.search, {
    decode: false,
  });
  const registerPlugin = videojs.registerPlugin || videojs.plugin;
  registerPlugin('shaka', initShaka);
  // registerPlugin('maxQualitySelector', initMaxQualitySelector);
  registerPlugin('ima', initIma);
  registerPlugin('ads', contribAds.default);
  const { current_live_cursor } = component.entityDetail;
  const { isHasSubcription, isRewatchCuratedLive, isLive } = component.state;
  const { disableControl, volumeLive, t } = component.props;
  component.videoPlayer = videojs(
    component.playerRef.current,
    {
      loop: false,
      liveui: true,
      liveTracker: true,
      plugins: {
        ...(component.configs.mux || {}),
        simulatedLiveEvent: { content: entityDetail },
      },
      html5: {
        nativeTextTracks: getConfigByKey('ui_theme') !== TENANT_NAME_ENUM.PROJECTW,
        // hls: {
        //   overrideNative: !videojs.browser.IS_SAFARI,
        // },
        vhs: {
          overrideNative: !videojs.browser.IS_SAFARI,
        },
        nativeAudioTracks: false,
        nativeVideoTracks: false,
      },
      textTrackSettings: false,
      autoplay: (isLive && !isMobile) || isHasSubcription ? 'any' : false,
      // autoplay: 'any',
      muted: disableControl ? !volumeLive : false,
      // responsive: true,
      userActions: {},
      nativesubtitles: true,
      nativeTextTrack: true,
      debug: false,
      controls: !disableControl,
      // nativeControlsForTouch: false,
      controlBar: {
        volumePanel: {
          inline: getConfigByKey('ui_theme') === TENANT_NAME_ENUM.PROJECTW,
        },
        liveDisplay: true,
        pictureInPictureToggle: false,
      },
      useDevicePixelRatio: true,
      limitRenditionByPlayerDimensions: false,
      // inactivityTimeout: 0,
    },
    async function onPlayerReady() {
      // if (getConfigByKey('features.maxQualitySelectorFeature')) {
      //   // this.maxQualitySelector = maxQualitySelector
      //   this.maxQualitySelector();
      // }

      this.poster(component.entityDetail.thumnail);
      const duration = this.duration();
      if (!isFinite(duration)) {
        const $player = jquery(this.player_.el_);
        $player.addClass('vjs-disable-offlive');
      }
      const $body = jquery('#body');
      this.hotkeys(hotkeysConfig(component, $body));
      const typeVideo = mime.lookup(linkPlay);
      let progress = entityDetail.progress || 0;
      let endCurentLive = false;
      const { isBlockAds } = component.state;

      if (this.ads && this.ads.reset) {
        this.ads.disableNextSnapshotRestore = true;
        this.ads.reset();
      }

      if (!disableControl) {
        addChilren(this, component, { entityDetail, renderLive });
      }
      if (current_live_cursor && !isRewatchCuratedLive) {
        const { current_video_position, real_duration, override_duration } = current_live_cursor;
        if (real_duration < current_video_position) {
          endCurentLive = true;
          const duration = override_duration - current_video_position;
          return getGapContent(component, parseInt(duration));
        }
        component.props.dispatchUpdateStatusGetGapContens(false);
      }
      if (!entityDetail.drm_session_info) {
        this.src({
          src: isBlockAds || (paramQuery && paramQuery.token) ? '' : linkPlay,
          type: typeVideo,
        });
      } else if (getConfigByKey('ui_theme') === TENANT_NAME_ENUM.PROJECTW) {
        drmEme(this, entityDetail);
      } else {
        initDrm(this, component);
      }

      if (replay.replay) {
        progress = 1;
        component._updateProgress(component.entityDetail.id, 1);
      }
      component.progress = progress;
      const options = {
        id: component.state.id,
        useMediaCuePoints: true,
        debug: false,
        adWillPlayMuted: false,
        contribAdsSettings: {
          prerollTimeout: isMobile ? 5000 : 3000,
        },
      };
      if (isHasSubcription || disableControl || !component.entityDetail.tagAds) {
        await checkAutoPlay(this, component);
        component.isReadyAds = true;
      } else {
        try {
          if (endCurentLive) component.entityDetail.tagAds = null;
          await this.ima(options);
          await init._addAdsVideo(component);
        } catch (e) {
          console.error('Error while initializing ads', e);
        }
      }
      return new Promise((resolve, reject) => {
        resolve();
      });
    },
  );
  if (getConfigByKey('ui_theme') === TENANT_NAME_ENUM.PROJECTW) {
    component.videoPlayer.maxQualitySelector({
      autoLabel: `${t('projectw__player.qualityAuto')}`, // Change the label from 'Auto' (default) to 'ABR'.,
      // index: 7,
      index: component.entityDetail.type === EPISODE_TYPE ? 5 : 7,
    });
    // component.videoPlayer.httpSourceSelector({
    //   autoLabel: 'Tự động',
    //   index: component.entityDetail.type === EPISODE_TYPE ? 5 : 7,
    // });
  }
};

const playerReady = (component, detailPage, t, renderLive) => {
  const { entityDetail } = component;
  const { account, volumeLive, disableControl, i18n, isPending, dpInitPlayer } = component.props;

  dpInitPlayer && dpInitPlayer(component.videoPlayer, component._setDuration, component);

  const { isBlockAds } = component.state;
  const player = component.videoPlayer;
  const $el = jquery(player.el_);
  const $player = jquery(player.player_.el_);
  const { payment_infors, unpublished_time, type } = entityDetail;
  const timeRemaining = (payment_infors && payment_infors.remaining_time) || 0;
  const hasRemaining = !!timeRemaining;

  let timeExpire = hasRemaining ? moment(payment_infors.end_time) : moment();
  if (type === SIMULATED_LIVE_EVENT && unpublished_time) {
    timeExpire = moment(unpublished_time);
  }

  const AVAILABLE_CONTENT_TYPE = [SIMULATED_LIVE_EVENT, LIVE_EVENT];

  const contentLive = AVAILABLE_CONTENT_TYPE.includes(type);

  const { bigPlayButton } = player;
  if (getConfigByKey('ui_theme') === TENANT_NAME_ENUM.PROJECTW && isPending) {
    player.on('play', () => {
      player.pause();
      $player.parent().find('.vjs-control-bar').addClass('pending__controlbar');
    });
    jquery(bigPlayButton.el_).addClass('pending__button');
    jquery(bigPlayButton.el_).append(
      jquery(`<div class="vjs-info-play-button pendingVideo">
      <p class="vjs-info-play-button--txt">Play</p>
      <p class="vjs-info-play-button--remaining"> ${t('playButton.pendingStatusTxt')} </p>
    </div>`),
    );
  }
  if (getConfigByKey('ui_theme') === TENANT_NAME_ENUM.PROJECTW && !isPending) {
    jquery(bigPlayButton.el_).append(
      jquery(`<div class="vjs-info-play-button">
      <p class="vjs-info-play-button--txt">Play</p>
      <p class="vjs-info-play-button--remaining ${!hasRemaining ? 'expire' : ''}">${t(
        'playButton.remaingTimeTxt',
        {
          time: contentLive ? '' : timeExpire.format('h:mm A'),
          year: timeExpire.year(),
          month:
            i18n.language === 'en'
              ? moment.monthsShort('-MMM-', timeExpire.month())
              : timeExpire.month() + 1,
          date: timeExpire.date(),
          datetime: contentLive
            ? timeExpire.format('MMM Do, YYYY')
            : timeExpire.format('h:mm A, MMM Do, YYYY'),
        },
      )}</p>
    </div>`),
    );
  }

  const volumeDefault =
    detailPage.player.volume === null ? component.state.lastVolume : detailPage.player.volume;
  player.ready(() => {
    if (
      (!player.ads || !player.ima) &&
      !isBlockAds &&
      (!account || (account && !account.profile.has_subscription))
    ) {
      component.setState({
        isBlockAds: true,
      });
      player.src({
        src: '',
      });
      return;
    }
    component._setPropertyControlBar();
    init.handelEvent(component, renderLive);
    player.on('loadeddata', event => {
      if (entityDetail.type !== CURATED_LIVE) {
        component.duration = player.duration();
      }
      const controlBar = player.getChild('controlBar');
      initScubberThumbnail(component);
      const subsCapsButton = controlBar.getChild('subsCapsButton').el();
      const firstMenuItem = subsCapsButton.getElementsByClassName('vjs-menu-item-text')[0];
      firstMenuItem.innerHTML = t('video.subtitle');
      player.volume(disableControl ? volumeLive : volumeDefault);
      if (renderLive) {
        const openEpg = setInterval(() => {
          if ($player.hasClass('vjs-ad-playing')) {
            return;
          }
          if (disableControl) return clearInterval(openEpg);
          if (tenantEnvConfig.ui_theme !== 'escondido') {
            $player.addClass('vjs-epg-open').parent().addClass('vjs-epg-open');
          }
          clearInterval(openEpg);
        }, 1000);
      }
    });
    jquery(`#${player.id_}`).focus();
  });
};

const detectAdBlocker = (component, account) => {
  head.appendChild(script);
};

export { initData, initPlayer, playerReady, detectAdBlocker };
